import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable';
import 'core-js';
import './polyfill'
import React from 'react';
import { createRoot } from "react-dom/client";
import App from './App';
import * as serviceWorker from './serviceWorker';

import { icons } from './assets/icons'



React.icons = icons
const container = document.getElementById("root");
const root = createRoot(container);

root.render(
    <App/>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
