import React, { Suspense } from "react";
import { HashRouter, Route, Routes } from "react-router-dom";
import "./scss/style.scss";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { getItemFromSessionStore } from "./utils";
import { Provider } from "react-redux";
import store from "./store";
const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));

// Pages
const Login = React.lazy(() => import("./views/login/Login"));
const Register = React.lazy(() => import("./views/pages/register/Register"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));
const Logout = React.lazy(() => import("./views/logout/Logout"));
const ChangePassword = React.lazy(() =>
  import("./views/pages/change-password")
);

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = React.useState(false);
  // const [imageUrl, setImageUrl] = React.createContext('');
  React.useEffect(() => {
    let isActive = true;
    if (isActive) {
      const token = getItemFromSessionStore("token");
      if (token) {
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
      }
    }
    return () => {
      isActive = false;
    };
  }, []);
  return (
    <Provider store={store}>
      <HashRouter>
        <Suspense fallback={loading}>
          <Routes>
            <Route path="/login" name="Login Page" element={<Login />} />
            <Route path="/logout" name="Login Out" element={<Logout />} />
            <Route
              path="/register"
              name="Register Page"
              element={<Register />}
            />
            <Route path="/404" name="Page 404" element={<Page404 />} />
            <Route path="/500" name="Page 500" element={<Page500 />} />
            <Route
              path="/change-password"
              name="Change Password"
              element={<ChangePassword />}
            />
            {/* <Route
              path="/"
              name="Home"
              element={isLoggedIn ? <TheLayout /> : <Login />}
            /> */}
            <Route
              path="*"
              name="Home"
              element={isLoggedIn ? <TheLayout /> : <Login />}
            />
            {/* <Route  path="/" name="Login Page" element={props => <Login />} /> */}
          </Routes>
        </Suspense>
      </HashRouter>
      <ToastContainer />
    </Provider>
  );
};

export default App;
